import { Close } from "@mui/icons-material";
import { Alert, AlertColor, IconButton, Snackbar } from "@mui/material";
import axios from "axios";
import {
  createContext,
  Fragment,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

export const SnackbarContext = createContext({
  error: (_e: unknown, _message: string) => {},
  showMessage: (_message: string, _severity?: AlertColor) => {},
});

export const SnackbarProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [message, setMessage] = useState<string | null>(null);
  const [messageOpen, setMessageOpen] = useState(false);
  const [severity, setSeverity] = useState<AlertColor | undefined>();

  const handleClose = useCallback(() => {
    setMessageOpen(false);
  }, []);

  const showMessage = useCallback((message: string, severity?: AlertColor) => {
    setMessage(message);
    setMessageOpen(true);
    setSeverity(severity);
  }, []);

  const error = useCallback(
    (e: unknown, message: string) => {
      if (axios.isAxiosError(e)) {
        showMessage(
          (e.response?.status ?? 500) < 500
            ? (e.response?.data as any).message
            : message,
          "error"
        );
      } else if (e instanceof Error) {
        showMessage(e.message, "error");
        console.error(e);
      } else {
        showMessage(message, "error");
        console.error(e);
      }
    },
    [showMessage]
  );

  const action = useMemo(
    () => (
      <Fragment>
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={handleClose}
        >
          <Close fontSize="small" />
        </IconButton>
      </Fragment>
    ),
    [handleClose]
  );

  return (
    <SnackbarContext.Provider
      value={{
        error,
        showMessage,
      }}
    >
      {children}
      <Snackbar
        action={action}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        autoHideDuration={6000}
        open={messageOpen}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={severity}
          variant="filled"
          sx={{ fontSize: 12 }}
        >
          {message}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
};

export function useSnackbar() {
  return useContext(SnackbarContext);
}
