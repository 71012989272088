import { useIsLogin } from "./base/hooks";
import { LoginPage } from "./components/pages/login";
import { AuthApp } from "./components/templates/auth-app";

export function App() {
  const isLogin = useIsLogin();

  if (!isLogin) {
    return <LoginPage />;
  }

  return <AuthApp />;
}
