import { useMemo } from "react";
import { Route, Routes } from "react-router-dom";
import { useAdminListStore } from "../../../features/store/hooks";
import { TopPage } from "../../pages/top";

export function AuthApp() {
  const listStore = useAdminListStore();
  const stores = useMemo(() => listStore.data ?? [], [listStore.data]);
  console.log(stores);

  return (
    <Routes>
      <Route path="/" element={<TopPage />} />
    </Routes>
  );
}
