import axios from "axios";

export async function fetchIdToken() {
  return "test";
}

export async function authAxiosInstance() {
  const idToken = await fetchIdToken();
  return axios.create({
    baseURL: process.env.REACT_APP_API_HOST,
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  });
}
