import { useQuery } from "@tanstack/react-query";
import { AdminListStore } from ".";
import { useSnackbar } from "../../components/providers/snackbar";

export function useAdminListStore() {
  const { error } = useSnackbar();

  return useQuery(
    ["AdminListStore"],
    async () => {
      return AdminListStore({});
    },
    {
      onError: (e) => error(e, "店舗一覧の取得時にエラーが発生しました"),
    }
  );
}
